import React from 'react'
import { ReactiveList } from '@appbaseio/reactivesearch'
import dictionary from './dictionary'
var moment = require('moment');
require('moment/locale/da');
moment().format();

function SearchDetails(props) {

    const showModal = (changes) => {

        let modal = document.getElementById("myModal");
        modal.style.display = "block";
        let modalContent = document.getElementById("myModalContent");
        let historyTable = '<table class="table table-striped"><thead class="thead-dark"><tr><th scope="col">Ændret:</th><th scope="col">Bruger</th><th scope="col">Ændringstype</th><th scope="col">Felt</th><th scope="col">Gammel værdi</th><th scope="col">Ny værdi</th></tr></thead><tbody>';
        let history = changes.split("|");
        history.forEach(function (item, index, array) {
            let items = [];
            items.push(item.split(";"));
            historyTable = historyTable + '<tr><td>' + items[0][0] + '</td><td>' + items[0][1] + '</td><td>' + items[0][2] + '</td><td>' + items[0][3] + '</td><td>' + items[0][4] + '</td><td>' + items[0][5] + '</td></tr>';
        });
        historyTable = historyTable + '</tbody></table>';
        modalContent.innerHTML = historyTable;
    }


    return (
        <React.Fragment>

            <ReactiveList
                componentId="ResultListDetail"
                dataField="EntityName"
                from={0}
                size={1}
                pagination={false}
                showResultStats={false}
                stream={false}
                loader={"Henter enhed"}
                className="result-list--detail"
                defaultQuery={() => ({
                    query: { "match": { "_id": props.entityId } }
                })}
                renderItem={(res) => {
                    return (
                        <React.Fragment key={res._id}>
                            <div className="col-12 text-center mb-3 navbar-text--black">{res.EntityName}</div>
                            <div className="row justify-content-end">
                                <div className="col-12">
                                    {res.SorUserChanges &&
                                        <div>
                                            <button className="btn btn-sm btn-light dropdown-toggle" type="button" id="dropdownHistoryButton" onClick={() => showModal(res.SorUserChanges)}>
                                                Ændringshistorik
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <div><label>Enhedstype</label></div>
                                    <div>{res.EntityTypeName}</div>
                                </div>

                                <div className="col-6">
                                    <div><label>SOR-type</label></div>
                                    <div>{dictionary[res.SorType]}</div>
                                </div>

                                <div className="col-6">
                                    <div><label>Klinisk adm. niveau</label></div>
                                    <div>{res.OrganizationalLevelName}</div>
                                </div>

                                <div className="col-6">
                                    <div><label>Gældende fra</label></div>
                                    <div>{moment(res.FirstFromDate.toString()).format('LL')}</div>
                                </div>

                                <div className="col-6">
                                    <div><label>Gældende til</label></div>
                                    {res.ToDate &&
                                        <div>{moment(res.ToDate.toString()).format('LL')}</div>
                                    }
                                </div>

                                <div className="col-6">
                                    <div><label>SOR kode</label></div>
                                    <div>{res.SorId}</div>
                                </div>



                                {res.ShakId &&
                                    <div className="col-6">
                                        <div><label>Shak kode</label></div>
                                        <div>{res.ShakId}</div>
                                    </div>
                                }

                                {res.HealthInstitutionEntityName &&
                                    <div className="col-6">
                                        <div><label>SI Parent</label></div>
                                        <div>{res.HealthInstitutionEntityName}</div>
                                    </div>
                                }

                                {res.ProviderId &&
                                    <div className="col-6">
                                        <div><label>Ydernummer</label></div>
                                        <div>{res.ProviderId}</div>
                                    </div>
                                }
                                {res.InstitutionOwnerCvrNumberId &&
                                    <div className="col-12">
                                        <div><label>Institutionsejer CVR</label></div>
                                        <div>{res.InstitutionOwnerCvrNumberId}</div>
                                    </div>
                                }

                                <div className="col-12 text-center mb-3 mt-3 navbar-text--black">Kontaktoplysninger</div>

                                {res.VisitingAddress &&
                                    <div className="col-6">
                                        <div><label>Besøgsadresse</label></div>
                                        <div>{res.VisitingAddress}</div>
                                        <div>{res.VisitingAddressPostCodeId} {res.VisitingAddressDistrictName}</div>
                                    </div>
                                }

                                {res.PostalAddress &&
                                    <div className="col-6"><label>Postadresse</label>
                                        <div>{res.PostalAddress}</div>
                                        <div>{res.PostalPostCodeId} {res.PostalDistrictName}</div>
                                        <div>{res.PostalAddressRegionName}</div>
                                    </div>
                                }

                                {res.VirtualAddressTelephoneNumber &&
                                    <div className="col-6"><label>Telefon nr.</label>
                                        <div>{res.VirtualAddressTelephoneNumber}</div>
                                    </div>
                                }

                                {res.VirtualAddressEmailAddress &&
                                    <div className="col-12">
                                        <div><label>E-mail</label></div>
                                        <div><a href={"mailto:" + res.VirtualAddressEmailAddress}>{res.VirtualAddressEmailAddress}</a></div>
                                    </div>
                                }

                                {res.SpecialityName &&
                                    <React.Fragment>

                                        <div className="col-12 text-center mb-3 mt-3 navbar-text--black">Enhedens specialer</div>


                                        <div className="col-12">
                                            <label>Specialer</label>
                                            <div>{res.SpecialityName.join(", ")}</div>
                                        </div>
                                    </React.Fragment>
                                }

                                <div className="col-12 text-center mb-3 mt-3 navbar-text--black">Lokationsoplysninger</div>

                                {res.EanLocationCode &&
                                    <div className="col-6"><label>Lokationsnummer</label>
                                        <div>{res.EanLocationCode}</div>
                                    </div>
                                }

                                {res.EanLocationCodeRegionName &&
                                    <div className="col-6"><label>Region</label>
                                        <div>{res.EanLocationCodeRegionName}</div>
                                    </div>
                                }

                                {res.EanLocationCodeSystemTypename &&
                                    <div className="col-6"><label>Systemleverandør</label>
                                        <div>{res.EanLocationCodeSystemTypename}</div>
                                    </div>
                                }

                                {res.EanLocationCodeCommunicationSupplierName &&
                                    <div className="col-6"><label>Netoperatør</label>
                                        <div>{res.EanLocationCodeCommunicationSupplierName}</div>
                                    </div>
                                }

                                {res.PharmacyId &&
                                    <React.Fragment>

                                        <div className="col-12 text-center mb-3 mt-3 navbar-text--black">Apoteksoplysninger</div>
                                        <div className="col-12"><label>Apoteksnummer</label>
                                            <div>{res.PharmacyId}</div>
                                        </div>

                                    </React.Fragment>
                                }
                                {res.GeographicalLocalisationName &&
                                    <React.Fragment>
                                        <div className="col-6"><label>Geografisk lokation</label>
                                            <div>{res.GeographicalLocalisationName}</div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>

                        </React.Fragment>
                    )
                }
                }
            >
            </ReactiveList>
        </React.Fragment>
    )
}
export default SearchDetails