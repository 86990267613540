import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretDown, faCircle } from '@fortawesome/free-solid-svg-icons'

library.add(faCaretRight, faCaretDown, faCircle)

function FirstLetterBucket(props) {

    /*Handle clicks on �Privat� aggregations which is mapped to first letter
    When clicked the state is updated and the entities which starts with the given letter is displayed.*/
    const handleFirstLetterClick = (key) => {
        let array = Array.from(props.showFirstLetterChildren);
        let index = array.indexOf(key);
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(key);
        }
        props.setShowFirstLetterChildren(array);
    }


    if (props.firstLetter != null) {

        return (
            <a href={"#FirstLetter" + props.firstLetter} className={props.showFirstLetterChildren.indexOf(props.firstLetter) === -1 ? "collapsed" : ""} aria-expanded="false"
                onClick={() => handleFirstLetterClick(props.firstLetter)}>

                <span className="circle"><FontAwesomeIcon icon="circle" /><FontAwesomeIcon icon="circle" /></span>
                <span className="caret-right"><FontAwesomeIcon icon="caret-right" /></span>
                <span className="caret-down"><FontAwesomeIcon icon="caret-down" /></span>
                {props.firstLetter}
            </a>
        )
    }
    return null;

}

export default FirstLetterBucket