import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretDown, faCircle } from '@fortawesome/free-solid-svg-icons'

library.add(faCaretDown, faCaretRight, faCircle)


function RegionBucket(props) {


    /*Handle clicks on the first aggregation (�Privat�, �Kommune�, �Stat�, �Region�). 
    When clicked the state is updated and the sub aggregation of the clicked entity is displayed.*/
    const handleRegionClick = (key) => {
        let array = Array.from(props.showRegionChildren);
        let index = array.indexOf(key);
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(key);
        }
        props.setShowRegionChildren(array);
    }


    if (props.region != null) {
        return (
            <a key={props.region.key} href={"#Re" + props.region.key.replace(/\s+/g, '')}
                className={props.showRegionChildren.indexOf(props.region.key) === -1 ? "collapsed" : ""}
                aria-expanded="false" aria-controls={props.region.key.replace(/\s+/g, '')}
                onClick={() => handleRegionClick(props.region.key)}>

                <span className="circle"><FontAwesomeIcon icon="circle" /></span>
                <span className="caret-right"><FontAwesomeIcon icon="caret-right" /></span>
                <span className="caret-down"><FontAwesomeIcon icon="caret-down" /></span>

                {props.region.key} ({props.region.doc_count})
            </a>
        )
    }
    return null;
}
export default RegionBucket