const dictionary = {
    IE: "Institutionsejer",
    SI: "Sundhedsinstitution",
    OE: "Organisatorisk enhed",
    VersionHistory: {
      ADDRESS_ACCESS_ID: "",                       
      COMMUNICATION_SUPPLIER: "Netoperatør",
      COUNTRY_ID_CODE: "Landekode",

      Lountry_id_code: "Land",
      CX05_ADMITTED: "Sengeafsnit",
      CX09_OUTPATIENT: "Ambulant",
      DATE_FROM: "Ændret den",
      DATE_TO: "Afsluttet",
      DISTRICT_NAME: "By",
      EAN_LOCATION_CODE: "Lokationsnummer",
      EAN_LOCATION_STATE: "Nedarvningsstatus",
      EDI_ADMINISTRATOR: "EDI administrator",
      EMAIL_ADDRESS: "E-mail",
      FAX_NUMBER: "FAX-nummer",
      FLOOR_ID: "Etage",
      GEO_LOCAION_IDENTIFIER: "Geo Id",
      GEO_LOCATION_NAME: "Geo lokation",
      GEOGRAPHICAL_PARENT_CODE: "Geografisk forældre kode",
      GEOGRAPHICAL_TYPE: "Geografisk type",
      HAS_GEOGRAPHICAL_LOCALISATION: "Har geografisk lokation",      
      INSTITUTION_NAME: "Institutions navn",
      INSTITUTION_TYPE: "Sundhedsinstitutionens type",
      IS_VALIDATED: "Kontroleret",      
      LOCAL_ATTRIBUTE1: "Lokal attribut nr. 1",
      LOCAL_ATTRIBUTE2: "Lokal attribut nr. 2",
      LOCAL_ATTRIBUTE3: "Lokal attribut nr. 3",
      LOCAL_ATTRIBUTE4: "Lokal attribut nr. 4",
      LOCAL_ATTRIBUTE5: "Lokal attribut nr. 5",
      LOCAL_CODE: "Lokalkode",
      LONGPARENTSORCODE: "Parent SOR kode",
      MUNICIPALITY_CODE: "Kommunekode",
      Non_ACTIVE: "Ikke aktiv",
      NOTE: "Noter",
      ONLY_INTERNAL: "Intern",
      OWNER_NAME: "Ejers navn",
      OWNER_TYPE: "Ejertype",
      P_Number: "Produktionsnummer",
      PHARMACY_CODE: "Apoteksnummer",
      PHONE_NUMBER: "Telefonnummer",
      POST_CODE_ID: "Postnummer",
      PROVIDER_ID: "Ydernummer",
      REGION_CODE: "Region",
      REGIONCODE: "Region",      
      SHAK1_CODE: "SKS",
      STREET_BUILDING_ID: "Husnummer",
      STREET_CODE: "Vejkode",
      STREET_CODE_TYPE: "Vejkodetype",
      STREET_NAME: "Gade",
      SUITE_ID: "Lejlighed",
      SYSTEM_SUPPLIER: "Systemleverandør",
      SYSTEM_TYPE: "EDB-system",
      UNIT_NAME: "Organisationens navn",
      UNIT_TYPE: "Organisationstype",
      WEBSITE: "Hjemmeside",
      
      X: "Geografisk længde",
      Y: "Geografisk bredde",
      1: "Oprettelse",
      2: "Redigering",
      3: "Sletning",
      er: "Forældrerelation",
      hi: "Sundhedsinstitution",
      io: "Ejer",                     
      lc: "Lokationsnummer",
      ou: "Organisatorisk enhed",
      pa: "Post adresse",
      SE:"Edibeskedder",      
      va: "Elektronisk adresse",
      False: "Falsk",
      True: "Sandt"


    }
  };
  export default dictionary  