function reactArray(componentId){
    let arr = ["FreetextSearch",
        "SorIdController",
        "EntityTypeNameSearch",
        "OrganizationalLevelNameSearch", // ID-27
        "TodataSearch",
        "SpecialityListSearch",
        "SorTypeSearch",
        "EanLocationCodeSearch",
        "ShakIdSearch",
        "PostalAddressSearch",
        "RegionNameSearch",
        "PostalMunicipalityNameSearch",
        "PostalCodeRangeStart",
        "PostalCodeRangeEnd",
        "ValidFromSearch",
        "EanRegionNameSearch", 
        "ModifiedDateSearch",
        "CodeSystemSearch",
        "CodeSystemSupplierName",
        "CommunicationSupplierSearch",
        "EdiFactSearch",
        "InstitutionOwnerEntityNameSearch",  
    ]
    const index = arr.indexOf(componentId)
    if(index > -1 ){
        arr.splice(index, 1)
    }
    return arr
} 
export default reactArray 