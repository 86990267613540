import React from 'react'
import { CategorySearch } from '@appbaseio/reactivesearch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faSearch)

/* SearchHeader is the component that renders the category search field and the logo in the App header. */
function SearchHeader(props) {

    const handleFreetextSearch = (event) => {
        props.handleFilterChange("FreetextSearch", event);
    }

    return (
        <header className="row search-header">

            <div className="search-header__searchfield pl-3 pr-3">

                <CategorySearch
                    componentId="FreetextSearch"
                    dataField={
                        [
                            "EntityName",
                            "PostalAddress",
                            "VisitingAddress",
                            "ShakId",
                            "SorId",
                            "EanLocationCode",
                            "InstitutionOwnerCvrNumberId",
                            "PharmacyId",
                            "ProviderId"
                        ]
                    }
                    placeholder="Søg efter SOR enhed"
                    categoryField={"EntityName.keyword"}
                    autosuggest={true}
                    highlight={true}
                    URLParams={true}
                    iconPosition="left"
                    showClear={true}
                    filterLabel="Fritekst"
                    title="SOR - Sundhedsvæsenets Organisationsregister"
                    icon={<FontAwesomeIcon icon="search" />}
                    className="freetext-search"
                    render={() => ( <div></div> )}
                    onValueChange={handleFreetextSearch}
                    customQuery={
                        function (value, props) {


                            if (!value || value === '') {
                                return {
                                    "track_total_hits": true,
                                    "query": {
                                        "match_all": {}
                                    }
                                }
                            }

                            let minimum_should_match = "1"

                            if (value.includes('"')) {
                                minimum_should_match = "100%"
                            }

                            if (value.includes("*") || value.includes("?")) {
                                return {
                                    "track_total_hits": true,
                                    "query": {
                                        "wildcard": {
                                            "EntityName": {
                                                "value": value                                          }
                                        }
                                    }
                                }

                            }

                            return {
                                "track_total_hits": true,
                                "query": {
                                    "bool": {
                                        "should": [
                                            {
                                                "multi_match": {
                                                    "query": value,
                                                    "fields": [
                                                        "LocalCode",
                                                        "EntityName",
                                                        "PostalAddress",
                                                        "VisitingAddress",
                                                        "ShakId",
                                                        "SorId",
                                                        "EanLocationCode",
                                                        "InstitutionOwnerCvrNumberId",
                                                        "PharmacyId",
                                                        "ProviderId"
                                                    ],
                                                    "type": "best_fields",
                                                    "operator": "or",
                                                    "fuzziness": 0
                                                }
                                            },
                                            {
                                                "multi_match": {
                                                    "query": value,
                                                    "fields": [
                                                        "LocalCode",
                                                        "EntityName",
                                                        "PostalAddress",
                                                        "VisitingAddress",
                                                        "ShakId",
                                                        "SorId",
                                                        "EanLocationCode",
                                                        "InstitutionOwnerCvrNumberId",
                                                        "PharmacyId",
                                                        "ProviderId"
                                                    ],
                                                    "type": "phrase_prefix",
                                                    "operator": "or"
                                                }
                                            }
                                        ],
                                        "minimum_should_match": minimum_should_match
                                    }
                                },
                                "aggs": {
                                    "EntityName.keyword": {
                                        "terms": {
                                            "field": "EntityName.keyword"
                                        }
                                    }
                                },
                                "highlight": {
                                    "pre_tags": ["<mark>"],
                                    "post_tags": ["</mark>"],
                                    "fields": {
                                        "LocalCode": {},
                                        "EntityName": {},
                                        "PostalAddress": {},
                                        "VisitingAddress": {},
                                        "ShakId": {},
                                        "SorId": {},
                                        "EanLocationCode": {},
                                        "InstitutionOwnerCvrNumberId": {},
                                        "PharmacyId": {},
                                        "ProviderId": {}
                                    }
                                }, "size": 3000
                            }

                        }
                    }
                /> {/* CategorySearch */}

            </div>

            <div className="logo-container"><img className="logo" src="logo.png" alt="logo"></img></div>
        </header>
    )
}

export default SearchHeader