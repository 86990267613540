
    // Detect Windows OS and presense of IE 11
    // Use this to target style or script changes required to work in IE11 on specific Window OS's
    // 

    const userAgent = window.navigator.userAgent
    const HTML = document.querySelector("html")

    let isWin7 = userAgent.indexOf("Windows NT 6.1") > -1 ? true : false
    let isWin8 = userAgent.indexOf("Windows NT 6.2") > -1 ? true : false
    let isWin81 = userAgent.indexOf("Windows NT 6.3") > -1 ? true : false
    let isWin10 = userAgent.indexOf("Windows NT 10") > -1 ? true : false
    let isIE11 = userAgent.indexOf("Trident/7.0") > -1 ? true : false

    if(isWin10){
        HTML.setAttribute("isWin10","true")
    }

    if(isWin8){
        HTML.setAttribute("isWin8","true")
    }

    if(isWin81){
        HTML.setAttribute("isWin81","true")
    }

    if(isWin7){
        HTML.setAttribute("isWin7","true")
    }

    if(isIE11){
        HTML.setAttribute("isIE11","true")
    }
