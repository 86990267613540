import React, { useState, useEffect } from 'react'
import { ReactiveBase, SelectedFilters } from '@appbaseio/reactivesearch'
import SearchHeader from './components/SearchHeader'
import NavBar from './components/Navbar'
import Filters from './components/Filters'
import Freesearch from './FreeSearch/Freesearch'
import TreeSearch from './TreeStructure/TreeSearch';
import SearchDetails from './components/SearchDetails'
import MapSearch from './Map/Map';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';


/* Search page is the parent component for all pages except support pages. Search page includes child components which displays filters and search results. 
This structure enables filters to be preserved across components */
function SearchPage() {

    const location = useLocation();
    const navigate = useNavigate();

    const [searchString, setSearchString] = useState("");
    const [searchArray, setSearchArray] = useState([]);
    const [entityId, setEntityId] = useState(null);
    const [linkId, setLinkId] = useState('');
    const [showFields, setShowFields] = useState([
        "CheckEntityTypeName",
        "CheckOrganizationalLevelName",   // ID-27
        "CheckAddress",
        "CheckSpecialityName"]);
    const [showEntityTypeName, setShowEntityTypeName] = useState("");
    const [showTreeList, setShowTreeList] = useState([]);
    const [showFirstLetter, setShowFirstLetter] = useState(false);
    const [showSorId, setShowSorId] = useState(null);
    const [showFilters, setShowFilters] = useState(false);

    useEffect(() => {
        const scrollPosition = sessionStorage.getItem('scrollPosition');
        if (scrollPosition) {
            sessionStorage.removeItem("scrollPosition");
            // et nasty hack, men ellers flytter scrollview fra valgt enhed
            window.setTimeout(function () {
                window.scrollTo(0, parseInt(scrollPosition));
            }, 200);
            window.setTimeout(function () {
                window.scrollTo(0, parseInt(scrollPosition));
            }, 400);
            window.setTimeout(function () {
                window.scrollTo(0, parseInt(scrollPosition));
            }, 800);
        }

    });

    const GetPathname = () => {
        return location.pathname;
    }

    /* When an entity is clicked that state is set
    which is used to display details about an entity*/
    const handleClick = (id) => {
        setEntityId(id);
    }

    /* When a map icon is clicked the user is transferred to the map component
    The state is updated with the id of the clicked entity */
    const handleMapClick = (_id) => {

        handleClick(_id);
        setLinkId('' + _id);
        navigate({
            pathname: "/map",
            search: "?SorIdController=" + _id
        });
    }
    /* When the tree list icon is clicked this functions calculate which branches should be opened.
    The user is transferred to the tree component */
    const handleTreeClick = (id, entityTypeName, institutionOwnerEntityName, treelist) => {

        let firstLetter = institutionOwnerEntityName.charAt(0) === "<" ? institutionOwnerEntityName.charAt(6) : institutionOwnerEntityName.charAt(0);
        let InstitutionOwnerEntityTypeName = entityTypeName.charAt(0).toUpperCase() + entityTypeName.slice(1);

        setShowEntityTypeName(InstitutionOwnerEntityTypeName);
        setShowTreeList(treelist);
        setShowFirstLetter(firstLetter);
        setShowSorId(treelist[0]);
        setEntityId(id);

        navigate({
            pathname: "/tree",
            search: searchString
        });

    }

    const HandleFilterChange = (filtername, event) => {
        let index = -1;
        for (let i = 0; index === -1 && i < searchArray.length; i++) {
            if (searchArray[i][0] === filtername)
                index = i;
        }
        if (index >= 0) {
            searchArray.splice(index, 1);
        }
        if (filtername === 'TodataSearch' && (event === '1' || event === '')) { // Default - den behøver vi ikke gemme
            createSearchString();
            return;
        }

        if (filtername === 'SorIdController' && event === '') {
            setEntityId(null);
            setLinkId('');
            createSearchString();
            return;
        }

        if (filtername === 'FreetextSearch' && event === '') {
            createSearchString();
            return;
        }

        searchArray.push([filtername, event]);
        createSearchString();
    }

    const createSearchString = () => {
        let ss = "";
        let prefix = "?";

        for (let i = 0; i < searchArray.length; i++) {
            if (Array.isArray(searchArray[i][1])) {
                if (searchArray[i][1].length > 0) {
                    ss += prefix + searchArray[i][0] + "=" + encodeURIComponent("[\"" + searchArray[i][1].join("\",\"") + "\"]");
                    prefix = "&";
                }
            } else if (searchArray[i][1] !== null) {
                ss += prefix + searchArray[i][0] + "=" + encodeURIComponent("\"" + searchArray[i][1] + "\"");
                prefix = "&";
            }
        }
        setSearchString(ss);
    }


    /* Handle which entity fields is shown to the user. The state is updated with an array of fields */
    const handleShowFieldsClick = (event) => {
        let array = Array.from(showFields);
        let index = array.indexOf(event.target.id)
        if (index !== -1) {
            array.splice(index, 1)
            setShowFields(array);
        }
        else {
            array.push(event.target.id)
            setShowFields(array);
        }
    }
    /* Handle visibility of search filters. This is done via state and dynamic render of CSS classes. */
    const handleToggleFilters = () => {
        setShowFilters(!showFilters);
    }

    const closeModal = () => {
        document.getElementById("myModal").style.display = "none"
    }


    return (
        // ReactiveBase is an Reactive Search component https://opensource.appbase.io/reactive-manual/getting-started/reactivebase.html
        <ReactiveBase
            app={process.env.REACT_APP_INDEX}
            url={process.env.REACT_APP_URL}
            credentials={process.env.REACT_APP_USER}
            mapKey={process.env.REACT_APP_MAPKEY}
            transformRequest={(e) => { localStorage.setItem("elasticsearchquery", e.body.split('\n')[1]); return e; }}
            theme={{
                typography: {
                    fontFamily: 'Arial, Helvetica, sans-serif',
                }
            }}
        >
            <div id="myModal" className="history-modal">
                <div className="history-modal-content">
                    <span style={{ textAlign: 'right' }} className="close" onClick={closeModal}>&times;</span>
                    <div id="myModalContent">
                        <p>Some text in the Modal..</p>
                    </div>
                </div>
            </div>
            <div className="main">
                <SearchHeader handleFilterChange={HandleFilterChange} />
                <div className="row navbarHeader">
                    <NavBar class="searchpage" SearchParams={searchString} />
                </div>
                <div className="row search-body">
                    <div className={["/tree"].indexOf(GetPathname()) !== -1 && !showFilters ? "collapse" : "collapse show col-12 col-md-4 col-xl-3 mt-3"}>
                        <Filters linkId={linkId} setLinkId={setLinkId} handleFilterChange={HandleFilterChange} search="todo" />
                    </div>
                    <div className={["/tree"].indexOf(GetPathname()) !== -1 && !showFilters ? "col-12 cold-md-12 col-xl-9 top-seach-tree" : "col-12 col-md-8 col-xl-6 top-seach-tree"}>
                        <div className="mt-3 selected-filters">
                            {/* SelectedFilters creates a selectable filter UI view displaying the current selected values from other components. 
                                This component is useful for improving selection accessibility of other components. */}
                            <SelectedFilters
                                showClearAll={true}
                                clearAllLabel="Ryd filtre"
                            />
                        </div>

                        {/* Display search result components based on path */}
                        {GetPathname() === "/" &&
                            <Freesearch handleClick={handleClick} handleMapClick={handleMapClick} handleTreeClick={handleTreeClick} handleShowFieldsClick={handleShowFieldsClick} showFields={showFields} pathname={GetPathname()} />
                        }
                        {GetPathname() === "/map" &&
                            <MapSearch handleClick={handleClick} handleTreeClick={handleTreeClick} pathname={GetPathname()} />
                        }
                        {["/tree"].indexOf(GetPathname()) !== -1 &&
                            <TreeSearch selectedEntity={entityId} handleClick={handleClick}
                                handleShowFieldsClick={handleShowFieldsClick} showFields={showFields} showEntityTypeName={showEntityTypeName}
                                showTreeList={showTreeList} setShowTreeList={setShowTreeList} showFirstLetter={showFirstLetter} showSorId={showSorId}
                                showFilters={showFilters} handleToggleFilters={handleToggleFilters} pathname={GetPathname()} />
                        }

                    </div>
                    <div className="col-12 text-center mt-3 navbar-text--white navbar__details-header--mobile">Information om SOR enhed</div>
                    <div className="col-12 col-xl-3 mt-3">
                        {entityId &&
                            <SearchDetails entityId={entityId} />
                        }
                        {!entityId &&
                            <div>Klik på en enhed i listen for at se detaljerede oplysninger om pågældende enhed</div>
                        }
                    </div>
                </div>
            </div>

        </ReactiveBase>

    )

}
export default SearchPage