import React from 'react'
import { MultiList, MultiDropdownList, MultiDataList, DatePicker, SingleDataList, DataSearch } from '@appbaseio/reactivesearch'
import '../filters.scss';
import reactArray from './reactArray'
import dictionary from './dictionary'

var moment = require('moment');
moment().format();

/*Filters component renders all the filters shown in the left part of the screen. 
It consists of Reactive search components https://opensource.appbase.io/reactive-manual*/

function Filters(props) {

    const handleSorIdController = (event) => {
        props.setLinkId(event);
        props.handleFilterChange("SorIdController", event);
    }

    const handleEntityTypeNameSearch = (event) => {
        props.handleFilterChange("EntityTypeNameSearch", event);
    }

    const handleOrganizationalLevelNameSearch = (event) => {
        props.handleFilterChange("OrganizationalLevelNameSearch", event);   // ID-27
    }

    const handleTodataSearch = (event) => {
        props.handleFilterChange("TodataSearch", event);
    }

    const handleSpecialityListSearch = (event) => {
        props.handleFilterChange("SpecialityListSearch", event);
    }

    const handleSorTypeSearch = (event) => {
        props.handleFilterChange("SorTypeSearch", event);
    }

    const handleInstitutionOwnerEntityNameSearch = (event) => {
        props.handleFilterChange("InstitutionOwnerEntityNameSearch", event);
    }

    const handleEanLocationCodeSearch = (event) => {
        props.handleFilterChange("EanLocationCodeSearch", event);
    }

    const handleShakIdSearch = (event) => {
        props.handleFilterChange("ShakIdSearch", event);
    }

    const handlePostalAddressSearch = (event) => {
        props.handleFilterChange("PostalAddressSearch", event);
    }

    const handleRegionNameSearch = (event) => {
        props.handleFilterChange("RegionNameSearch", event);
    }

    const handlePostalMunicipalityNameSearch = (event) => {
        props.handleFilterChange("PostalMunicipalityNameSearch", event);
    }

    const handleValidFromSearch = (event) => {
        props.handleFilterChange("ValidFromSearch", event);
    }

    const handleModifiedDateSearch = (event) => {
        props.handleFilterChange("ModifiedDateSearch", event);
    }

    const handleEanRegionNameSearch = (event) => {
        props.handleFilterChange("EanRegionNameSearch", event);
    }

    const handleCodeSystemSearch = (event) => {
        props.handleFilterChange("CodeSystemSearch", event);
    }

    const handleCodeSystemSupplierName = (event) => {
        props.handleFilterChange("CodeSystemSupplierName", event);
    }

    const handleCommunicationSupplierSearch = (event) => {
        props.handleFilterChange("CommunicationSupplierSearch", event);
    }

    const handleEdiFactSearch = (event) => {
        props.handleFilterChange("EdiFactSearch", event);
    }

    const handlePostalCodeRangeStartChange = (event) => {
        props.handleFilterChange("PostalCodeRangeStart", event);
    }

    const handlePostalCodeRangeEndChange = (event) => {
        props.handleFilterChange("PostalCodeRangeEnd", event);
    }

    return (
        <React.Fragment>

            <div className="filter-list--detail testClass">
                <div className="notvisible">

                    <DataSearch
                        componentId="SorIdController"
                        dataField="_id"
                        showFilter={true}
                        defaultValue={"" + props.linkId}
                        filterLabel="Id"
                        URLParams={true}
                        onValueChange={handleSorIdController}
                        customQuery={function (value, props) {
                            if (value !== null && value !== '') {
                                return {
                                    "track_total_hits": true,
                                    "query": {
                                        "match": { "_id": value }
                                    }
                                }
                            }
                            return {
                                "track_total_hits": true,
                                "query": {
                                    "match_all": {}
                                }
                            }
                        }}
                    />
                </div>

                <MultiDropdownList
                    componentId="EntityTypeNameSearch"
                    dataField="EntityTypeName.keyword"
                    title="Enhedstype"
                    size={100}
                    onValueChange={handleEntityTypeNameSearch}
                    sortBy="asc"
                    showCount={true}
                    placeholder="Vælg SOR enhedstype"
                    react={{
                        and: reactArray("EntityTypeNameSearch")
                    }}
                    showFilter={true}
                    filterLabel="SOR enhedstype"
                    URLParams={true}
                    className="mb-3"
                />
                {/*ID-27 If no dropdown is shown - is OrganizationalLevelName missing from the datasource? */}
                <MultiDropdownList
                    componentId="OrganizationalLevelNameSearch"
                    dataField="OrganizationalLevelName.keyword"
                    title="Klinisk adm. niveau"
                    size={100}
                    onValueChange={handleOrganizationalLevelNameSearch}
                    sortBy="asc"
                    showCount={true}
                    placeholder="Vælg klinisk adm. niveau"
                    react={{
                        and: reactArray("OrganizationalLevelNameSearch")
                    }}
                    showFilter={true}
                    filterLabel="Klinisk adm. niveau"
                    URLParams={true}
                    className="mb-3"
                />
                <SingleDataList
                    componentId="TodataSearch"
                    dataField="Todate"
                    title="Enheds status"
                    onValueChange={handleTodataSearch}
                    showFilter={true}
                    showSearch={false}
                    URLParams={true}
                    filterLabel="Dato"
                    defaultValue="1"
                    className="search-filter"
                    defaultSelected="Kun aktuelle enheder"
                    data={
                        [
                            {
                                label: "Kun lukkede enheder",
                                value: 2
                            },
                            {
                                label: "Kun fremtidige enheder",
                                value: 3
                            },
                            {
                                label: "Alle enheder",
                                value: 4
                            }

                        ]
                    }
                    react={{
                        and: reactArray("TodataSearch")
                    }}
                    customQuery={
                        (value) => {
                            if (value === null || value === 1) {
                                return {
                                    "track_total_hits": true,


                                    "query": {
                                        "bool": {
                                            "must": [
                                                { "range": { "FromDate": { "lte": "now/d" } } },
                                                {
                                                    "bool": {
                                                        "minimum_should_match": 1,
                                                        "should": [
                                                            {
                                                                "bool": {
                                                                    "must_not": {
                                                                        "exists": {
                                                                            "field": "ToDate"
                                                                        }
                                                                    }
                                                                }
                                                            },
                                                            { "range": { "ToDate": { "gte": "now/d" } } }
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                }
                            }

                            // Gammelt script - det er fjernet, så enheden ikke er åben når fradato er lig med tildato.
                            // "script": "boolean latest = false; long timestampNow = new Date().getTime(); if (doc['IsLatest'].value == true) {long timestampSpecialFromDate = doc['FromDate'].value.toInstant().toEpochMilli(); if (doc['ToDate'].value.toInstant().toEpochMilli() == 0 ){return true;} if (timestampSpecialFromDate <= timestampNow && doc['ToDate'].value.toInstant().toEpochMilli() <= doc['FromDate'].value.toInstant().toEpochMilli()) {return true}} else if(doc['IsLatest'].value == false){long timestampSpecialToDate = doc['ToDate'].value.toInstant().toEpochMilli(); if (timestampSpecialToDate >= timestampNow && doc['ToDate'].value.toInstant().toEpochMilli() >= doc['FromDate'].value.toInstant().toEpochMilli()) {return true}} return latest;"


                            if (value === 2) {
                                return {
                                    "track_total_hits": true,
                                    "query": {
                                        "bool": {
                                            "must": [
                                                {
                                                    "range": {
                                                        "ToDate": {

                                                            "lt": "now/d"
                                                        }
                                                    }
                                                },
                                                {
                                                    "match": { "IsLatest": true }
                                                }
                                            ]
                                        }
                                    }
                                }
                            }
                            if (value === 3) {
                                return {
                                    "track_total_hits": true,
                                    "query": {
                                        "bool": {
                                            "must": [
                                                {
                                                    "range": {
                                                        "FirstFromDate": {
                                                            "gt": "now/d"
                                                        }
                                                    }
                                                }
                                            ],
                                            "filter": [
                                                {
                                                    "script": {
                                                        "script": "long fra = !doc.containsKey('FromDate') || doc['FromDate'].empty ? 0 : doc['FromDate'].value.toInstant().toEpochMilli(); long til = !doc.containsKey('ToDate') || doc['ToDate'].empty ? 0 : doc['ToDate'].value.toInstant().toEpochMilli(); return til == 0 || til >= fra;"
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                }
                            }
                            if (value === 4) {
                                return {
                                    "track_total_hits": true,
                                    "query": {
                                        "bool": {
                                            "filter": [
                                                {
                                                    "script": {
                                                        "script": "long fra = !doc.containsKey('FromDate') || doc['FromDate'].empty ? 0 : doc['FromDate'].value.toInstant().toEpochMilli(); long til = !doc.containsKey('ToDate') || doc['ToDate'].empty ? 0 : doc['ToDate'].value.toInstant().toEpochMilli(); return til == 0 || til >= fra;"
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                }
                            }
                        }
                    }
                />
                <MultiList
                    componentId="SpecialityListSearch"
                    dataField="SpecialityName.keyword"
                    title="Speciale"
                    size={100}
                    sortBy="asc"
                    queryFormat="or"
                    selectAllLabel="Alle Specialer"
                    showCheckbox={true}
                    showCount={true}
                    showSearch={true}
                    showMissing={false}
                    onValueChange={handleSpecialityListSearch}
                    placeholder="Vælg Speciale"
                    showFilter={true}
                    filterLabel="Speciale"
                    URLParams={true}
                    className="mb-3 multilistsearch "
                    react={{
                        and: reactArray("SpecialityListSearch")
                    }}

                />

                <MultiList
                    componentId="SorTypeSearch"
                    dataField="SorType.keyword"
                    onValueChange={handleSorTypeSearch}
                    title="SOR type"
                    size={5}
                    sortBy="asc"
                    selectAllLabel="Alle SOR typer"
                    showCheckbox={true}
                    showCount={true}
                    showSearch={false}
                    placeholder="Vælg SOR type"
                    className="mb-3 search-filter multilistsearch multilistsearch--sortype"
                    react={{
                        and: reactArray("SorTypeSearch")
                    }}
                    renderListItem={(label, count) => (
                        <div>{dictionary[label]} ({count})</div>
                    )}
                    showFilter={true}
                    showMissing={true}
                    filterLabel="SOR type"
                    URLParams={true}
                    transformData={(data) => {
                        // change the sortorder of the entities
                        // sortOrder array is the desired order. Modify as desired
                        const sortOrder = ["IE", "SI", "OE"]
                        let newData = data.reduce((accumulator, item) => {
                            accumulator[sortOrder.indexOf(item.key)] = item
                            return accumulator
                        }, [])
                        return newData
                    }}

                />
                <MultiList
                    componentId="InstitutionOwnerEntityNameSearch"
                    dataField="InstitutionOwnerEntityName.keyword"
                    title="Institutionsejer"
                    onValueChange={handleInstitutionOwnerEntityNameSearch}
                    showMissing={true}
                    showFilter={true}
                    filterLabel="Institutionsejer"
                    URLParams={true}
                    placeholder={"Vælg Institutionsejer"}

                    react={{
                        and: reactArray("InstitutionOwnerEntityNameSearch")
                    }}
                    className="mb-3 search-filter multilistsearch"
                    sortBy={"count"}
                />

                <div className="mb-3">
                    <MultiDataList
                        componentId="EanLocationCodeSearch"
                        dataField="EanLocationCode"
                        title="Søg kun i enheder med"
                        showFilter={true}
                        onValueChange={handleEanLocationCodeSearch}
                        showSearch={false}
                        URLParams={true}
                        filterLabel="vis"
                        className="search-filter singledatalist"
                        data={
                            [{
                                label: "Har Lokationsnummer",
                                value: 1
                            }]
                        }
                        react={{
                            and: reactArray("EanLocationCodeSearch")
                        }}
                        customQuery={(value) => {
                            if (value[0] === 1) {
                                return {
                                    "track_total_hits": true,
                                    "query": {
                                        "exists": { "field": "EanLocationCode" }
                                    }
                                }
                            }
                        }
                        }
                    />
                    <MultiDataList
                        componentId="ShakIdSearch"
                        dataField="ShakId"
                        showFilter={true}
                        showSearch={false}
                        URLParams={true}
                        onValueChange={handleShakIdSearch}
                        filterLabel="Enheder"
                        className="search-filter singledatalist"
                        data={
                            [{
                                label: "Har Shak kode",
                                value: 1
                            }]
                        }
                        react={{
                            and: reactArray("ShakIdSearch")
                        }}
                        customQuery={(value) => {
                            if (value[0] === 1) {
                                return {
                                    "track_total_hits": true,
                                    "query": {
                                        "exists": { "field": "ShakId" }
                                    }
                                }
                            }
                        }
                        }
                    />

                    <MultiDataList
                        componentId="PostalAddressSearch"
                        dataField="PostalAddress"
                        className="search-filter singledatalist"
                        showFilter={true}
                        showSearch={false}
                        URLParams={true}
                        onValueChange={handlePostalAddressSearch}
                        filterLabel="Enheder"
                        data={
                            [{
                                label: "Har Postadresse",
                                value: 1
                            }]
                        }
                        react={{
                            and: reactArray("PostalAddressSearch")
                        }}
                        customQuery={(value) => {
                            if (value[0] === 1) {
                                return {
                                    "track_total_hits": true,
                                    "query": {
                                        "exists": { "field": "PostalAddress" }
                                    }
                                }
                            }
                        }
                        }
                    />
                </div>

                <h5 className="pb-3 pt-3 ">Filtrer efter adresse</h5>

                <MultiDropdownList
                    componentId="RegionNameSearch"
                    dataField="PostalAddressRegionName.keyword"
                    title="Regioner"
                    size={10}
                    sortBy="asc"
                    showCount={true}
                    showMissing={true}
                    onValueChange={handleRegionNameSearch}
                    placeholder="Vælg Region"
                    className="mb-3"
                    react={{
                        and: reactArray("RegionNameSearch")
                    }}
                    showFilter={true}
                    filterLabel="Region"
                    URLParams={true}
                />
                <MultiDropdownList
                    componentId="PostalMunicipalityNameSearch"
                    dataField="PostalMunicipalityName.keyword"
                    title="Kommuner"
                    size={100}
                    sortBy="asc"
                    showCount={true}
                    onValueChange={handlePostalMunicipalityNameSearch}
                    placeholder="Vælg Kommune"
                    className="mb-3"
                    react={{
                        and: reactArray("PostalMunicipalityNameSearch")
                    }}
                    showFilter={true}
                    filterLabel="Kommune"
                    URLParams={true}
                />

                <h5 className="pb-3 pt-3 ">Filtrer efter Postnummer</h5>
                <div className="row mb-3">

                    <DataSearch
                        componentId="PostalCodeRangeStart"
                        placeholder="Laveste værdi"
                        dataField="PostalPostCodeId"
                        showFilter={true}
                        debounce={1000000}
                        onValueChange={handlePostalCodeRangeStartChange}
                        filterLabel="Postnummer start"
                        URLParams={true}
                        className="col-6"
                        customQuery={(value) => {
                            if (!value) {
                                return "";
                            }
                            return {
                                "track_total_hits": true,
                                "query": {
                                    "range": {
                                        "PostalPostCodeId": { "gte": value }
                                    }
                                }
                            }
                        }}
                    />

                    <DataSearch
                        componentId="PostalCodeRangeEnd"
                        placeholder="Højeste værdi"
                        dataField="PostalPostCodeId"
                        debounce={1000000}
                        onValueChange={handlePostalCodeRangeEndChange}
                        showFilter={true}
                        filterLabel="Postnummer slut"
                        URLParams={true}
                        className="col-6"
                        customQuery={(value) => {
                            if (!value) {
                                return "";
                            }
                            return {
                                "track_total_hits": true,
                                "query": {
                                    "range": {
                                        "PostalPostCodeId": { "lte": value }
                                    }
                                }
                            }
                        }}
                    />
                </div>

                <h5 className="pb-3 pt-3 ">Filtrer efter dato</h5>
                <DatePicker
                    componentId="ValidFromSearch"
                    dataField="FirstFromDate"
                    title="Enhed er gældende fra"
                    focused={false}
                    numberOfMonths={1}
                    onValueChange={handleValidFromSearch}
                    queryFormat="basic_date"
                    placeholder="Vælg Gældende fra dato"
                    showClear={true}
                    clickUnselectsDay={true}
                    showFilter={true}
                    filterLabel="gyldig-fra"
                    URLParams={true}
                    className="mb-3"
                    react={{
                        and: reactArray("ValidFromSearch")
                    }}
                    dayPickerInputProps={{}}
                    customQuery={
                        function (value, props) {
                            var newDate;

                            if (value != null) {
                                newDate = value.replace(/[-]/g, '');
                            } else {
                                newDate = value;
                            }


                            return {
                                "track_total_hits": true,
                                "query": {
                                    "range": {
                                        "FirstFromDate": {
                                            "gte": newDate
                                        }
                                    }
                                }
                            }
                        }
                    }

                />
                <DatePicker
                    componentId="ModifiedDateSearch"
                    dataField="UpdatedAt"
                    title="Enhed er ændret efter"
                    focused={false}
                    numberOfMonths={1}
                    onValueChange={handleModifiedDateSearch}
                    queryFormat="basic_date"
                    placeholder="Vælg Sidst ændret dato"
                    showClear={true}
                    clickUnselectsDay={true}
                    showFilter={true}
                    filterLabel="sidst-ændret"
                    URLParams={true}
                    className="mb-3"
                    react={{
                        and: reactArray("ModifiedDateSearch")
                    }}
                    customQuery={
                        function (value) {
                            var newDate;

                            if (value != null) {
                                newDate = value.replace(/[-]/g, '');
                            } else {
                                newDate = value;
                            }

                            return {
                                "track_total_hits": true,
                                "query": {
                                    "range": {
                                        "UpdatedAt": {
                                            "gte": newDate
                                        }
                                    }
                                }
                            }

                        }

                    }
                />

                <h5 className="pb-3 pt-3 ">Filtrer efter Lokationsnummer</h5>

                <MultiDropdownList
                    componentId="EanRegionNameSearch"
                    dataField="EanLocationCodeRegionName.keyword"
                    title="Region for lokationsnummer"
                    onValueChange={handleEanRegionNameSearch}
                    size={10}
                    sortBy="asc"
                    showCount={true}
                    showMissing={true}
                    placeholder="Vælg Region"
                    className="mb-3"
                    react={{
                        and: reactArray("EanRegionNameSearch")
                    }}
                    showFilter={true}
                    filterLabel="Region"
                    URLParams={true}
                />
                <MultiDropdownList
                    componentId="CodeSystemSearch"
                    dataField="EanLocationCodeSystemTypename.keyword"
                    onValueChange={handleCodeSystemSearch}
                    title="IT system"
                    sortBy="asc"
                    showCount={true}
                    placeholder="Vælg IT system"
                    className="mb-3"
                    react={{
                        and: reactArray("CodeSystemSearch")
                    }}
                    showFilter={true}
                    filterLabel="Region"
                    URLParams={true}
                />
                <MultiDropdownList
                    componentId="CodeSystemSupplierName"
                    dataField="EanLocationCodeSystemSupplierName.keyword"
                    title="Systemleverandør"
                    onValueChange={handleCodeSystemSupplierName}
                    sortBy="asc"
                    showCount={true}
                    placeholder="Vælg Systemleverandør"
                    className="mb-3"
                    react={{
                        and: reactArray("CodeSystemSupplierName")
                    }}
                />
                <MultiDropdownList
                    componentId="CommunicationSupplierSearch"
                    dataField="EanLocationCodeCommunicationSupplierName.keyword"
                    title="Net operatør"
                    size={10}
                    onValueChange={handleCommunicationSupplierSearch}
                    sortBy="asc"
                    showCount={true}
                    placeholder="Vælg Net operatør"
                    className="mb-3"
                    react={{
                        and: reactArray("CommunicationSupplierSearch")
                    }}
                    showFilter={true}
                    filterLabel="Net-operatør"
                    URLParams={true}
                />
                <MultiList
                    componentId="EdiFactSearch"
                    dataField="EdifactName.keyword"
                    title="EDI type"
                    sortBy="asc"
                    onValueChange={handleEdiFactSearch}
                    showCount={true}
                    placeholder="Vælg EDI type"
                    className="mb-3 search-filter multilistsearch"
                    react={{
                        and: reactArray("EdiFactSearch")
                    }}
                    showFilter={true}
                    filterLabel="EDI type"
                    URLParams={true}
                />


            </div>

        </React.Fragment>
    )
}
export default Filters