import React from 'react';
import { ReactiveList } from '@appbaseio/reactivesearch'
import TreeFields from './TreeFields'
import OeEntity from './OeEntity';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretDown, faCircle } from '@fortawesome/free-solid-svg-icons'

library.add(faCaretRight, faCaretDown, faCircle)
/*This component renders entities with a Level = 2. It subsequently calls OeEntity for children of a given entity.*/
function SiEntity(props) {

    /*This function boost SorId so if an entity is clicked it appears on top as first child of a given parent.*/
    const treeClickBuilder = () => {
        if (props.showSorId !== null) {
            return ({ "match": { "SorId.keyword": { "query": props.showSorId, "boost": 2 } } })
        }
        return ({ "match_all": {} });
    }


    /*This function calls two functions in StaticTree so a given entity´s details is displayed and the children displayed.*/
    const handleSiEntityClick = (e, _id, sorId) => {

        e.preventDefault()

        let array = Array.from(props.showSiChildren);
        let index = array.indexOf(sorId);
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(sorId);
            sessionStorage.setItem('scrollPosition', window.pageYOffset);
        }
        props.setShowSiChildren(array);

        props.handleClick(_id)
    }

    const renderData = (data, resultStats) => {

        if (data.length === 0) {
            return null;
        }

        let level = parseInt(props.level);
        let showCircle = parseInt(props.showCircle);

        let circles = []
        let nextLevel = level + 1
        let nextShowCircle = showCircle + 1
        for (let i = 0; i < showCircle; i++) {
            circles.push(<FontAwesomeIcon key={i} icon="circle" />)
        }

        return (
            <React.Fragment>

                {data.map((result) =>
                    <li className={"search-result--tree__circle search-result--tree__circle-" + showCircle} key={result._id}>
                        <span className="search-result--tree__inner-wrapper" data-selected={props.selectedOrNot(result._id)}>
                            <a id={result.SorId} className={props.showSiChildren.indexOf(result.SorId) === -1 ? "collapsed" : ""}
                                href={"#Oe" + result._id} onClick={(e) => handleSiEntityClick(e, result._id, result.SorId)}>

                                <span className="circle">{circles}</span>
                                <span className="caret-right"><FontAwesomeIcon icon="caret-right" /></span>
                                <span className="caret-down"><FontAwesomeIcon icon="caret-down" /></span>

                                <span className="search-result--tree__entityname">{result.EntityName}</span>

                            </a>
                            <TreeFields showFields={props.showFields} setShowTreeLines={props.setShowTreeLines} handleTreeFieldsEvent={props.handleTreeFieldsEvent}
                                EntityTypeName={result.EntityTypeName}
                                OrganizationalLevelName={result.OrganizationalLevelName}
                                SorType={result.SorType}
                                PostalAddress={result.PostalAddress}
                                PostalPostCodeId={result.PostalPostCodeId}
                                PostalDistrictName={result.PostalDistrictName}
                                VisitingAddress={result.VisitingAddress}
                                VisitingAddressPostCodeId={result.VisitingAddressPostCodeId}
                                VisitingAddressDistrictName={result.VisitingAddressDistrictName}
                                SpecialityName={result.SpecialityName}
                                InstitutionOwnerCvrNumberId={result.InstitutionOwnerCvrNumberId}
                                SorId={result.SorId}
                                ShakId={result.ShakId}
                                EanLocationCode={result.EanLocationCode}
                                ProviderId={result.ProviderId}
                                PharmacyId={result.PharmacyId}

                            />
                        </span>
                        <ul className={props.showSiChildren.indexOf(result.SorId) === -1 ? "collapse" : "show"} id={"Oe" + result._id} key={"Oe" + result._id}>

                            <OeEntity selectedOrNot={props.selectedOrNot} parentKey={result.SorId} showSorId={props.showSorId} handleClick={props.handleClick} showCircle={nextShowCircle} level={nextLevel} showFields={props.showFields}
                                showSiChildren={props.showSiChildren} setShowSiChildren={props.setShowSiChildren}
                                showOeChildren={props.showOeChildren} setShowOeChildren={props.setShowOeChildren}
                                setShowTreeLines={props.setShowTreeLines} />

                        </ul>
                    </li>
                )}

            </React.Fragment>
        )

    }

    /*
                     {resultStats.displayResults < resultStats.numberOfResults &&
                    <React.Fragment>
                        <span className="result-stats">Viser {resultStats.displayResults} ud af {resultStats.numberOfResults} </span>
                        <button type="button" className="btn btn-sm btn-link" onClick={props.loadMoreData}>Hent flere enheder</button>
                    </React.Fragment>
                }

     */

    /*Only show children if parent is part of array*/
    if (props.showIeChildren.indexOf(props.parentSorId) !== -1) {

        return (
            <ReactiveList
                componentId={"SiReactiveList" + props.parentSorId}
                dataField="EntityName"
                className="search-results--tree"
                loader="Henter enheder"
                renderNoResults={() => { return null; }}
                showResultStats={false}
                react={{
                    and: ["TodataSearch"]
                }}
                pagination={false}
                size={100}
                defaultQuery={() => {
                    return ({
                        "track_scores": true,
                        "sort": [{ "EntityName.keyword": { "order": "asc" } }],
                        "query": {
                            "bool": {
                                "must": [
                                    {
                                        "match": { "Treelist": props.parentSorId }
                                    },
                                    {
                                        "match": { "Level": props.level }
                                    }

                                ],
                                "should": treeClickBuilder()
                            }
                        }
                    })
                }
                }

                render={({
                    loading,
                    error,
                    data,
                    resultStats
                }) => renderData(data, resultStats)}



            />
        )
    }
    return null;
}

/*
                childTotal={null}
                handleChildTotal={function (total) { if (total != null) { this.childTotal = total } }}

 * 
 */

/*
                render={({
                    loading,
                    error,
                    data,
                    resultStats
                }) => renderData(data, resultStats)}

 */

export default SiEntity