import React, { useState, useEffect } from 'react';
import SearchHeader from '../components/SearchHeader'
import NavBar from '../components/Navbar'
import '../support.scss'
import { ReactiveBase } from '@appbaseio/reactivesearch'

/*This component renders the support pages which is plain text.*/
function Support() {

    const [id, setId] = useState(88687533);
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");

    useEffect(() => {
        //For at titlen til "Filte" og "Information om SOR enhed" findes disse med javascript og sættes midlertidligt med hvid baggrund så de er skjult. 
        const informationOmSorEnhedHeaderRight = document.getElementsByClassName("navbar__details-header");
        const filtreHeaderLeft = document.getElementsByClassName("navbar-text--white");

        informationOmSorEnhedHeaderRight[0].style.backgroundColor = "#F5F5F5";
        filtreHeaderLeft[0].style.backgroundColor = "#F5F5F5";

        informationOmSorEnhedHeaderRight[0].style.color = "#F5F5F5";
        filtreHeaderLeft[0].style.color = "#F5F5F5";

        getHelpPage(id);
    });

    const getHelpPage = (idSent) => {

        setId(idSent);

        let xhr = null;

        if (window.ActiveXObject) {
            xhr = new XMLHttpRequest("Microsoft.XMLHTTP");
        } else {
            if (window.XMLHttpRequest) {
                xhr = new XMLHttpRequest();
            }
        }

        xhr.open("POST", process.env.REACT_APP_HELP_API_URL, true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        xhr.responseType = "json";
        xhr.send(idSent);

        xhr.onload = (e) => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    if (!!navigator.userAgent.match(/Trident\/7\./)) {
                        setTitle(xhr.response.substring(xhr.response.indexOf('"title":') + 9, xhr.response.indexOf('","')));
                        setBody(xhr.response.substring(xhr.response.indexOf('"body":"') + 8, xhr.response.length - 2));
                    } else {
                        setTitle(e.target.response.title);
                        setBody(e.target.response.body);
                    }
                } else {
                    console.error(xhr.statusText);
                }
            }
        };
        xhr.onerror = function (e) {
            console.error(xhr.statusText);
        };
    }


    return (
        <ReactiveBase
            app={process.env.REACT_APP_INDEX}
            url="https://4f7853d00f5f4c2489895fab43e7cda7.eu-central-1.aws.cloud.es.io/"
            credentials="elastic:i3stC1oofHRPlq3Dt9ohmeZZ"
            mapKey="AIzaSyAvIh6jVVOZ5pIvCIH51s4_YL5YnFv_IkM"
            theme={{
                typography: {
                    fontFamily: 'Arial, Helvetica, sans-serif',
                }
            }}
        >
            <div className="main">
                <SearchHeader className="supportpage" />
                <div className="row navbarHeader">
                    <NavBar path="map" />
                </div>
                <div className="row search-body">
                    <div className="col-12 col-md-4 col-xl-3 mt-3">
                        <div className="nav flex-column support-nav" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a onClick={() => getHelpPage(88687533)} className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">Sådan søger du i SOR Browseren</a>
                            <a onClick={() => getHelpPage(99442741)} className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Om SOR</a>
                            <a onClick={() => getHelpPage(99442743)} className="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">Support</a>
                        </div>
                    </div>

                    <div className="col-12 col-md-8 col-xl-6 mt-3">
                        <div className="tab-content support-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                <h2>{title}</h2>
                                <div dangerouslySetInnerHTML={{ __html: body }}></div>
                            </div>
                            <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                <h2>{title}</h2>
                                <div dangerouslySetInnerHTML={{ __html: body }}></div>
                            </div>
                            <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                <h2>{title}</h2>
                                <div dangerouslySetInnerHTML={{ __html: body }}></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-4 col-xl-3 mt-3"></div>
            </div>

        </ReactiveBase >
    )
}
export default Support