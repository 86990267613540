import React from 'react';
import { ReactiveList } from '@appbaseio/reactivesearch'
import TreeFields from './TreeFields'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretDown, faCircle } from '@fortawesome/free-solid-svg-icons'
import handleHtmlMarkup from '../components/HandleHtmlMarkup'


library.add(faCaretRight, faCaretDown, faCircle)
/*This component renders entities with a Level > 2. It subsequently calls itself for children of a given entity.*/
function OeEntity(props) {

    /*This function calls two functions in StaticTree so a given entity´s details is displayed and the children displayed. */
    const handleOeEntityChildClick = (e, _id, sorId) => {

        e.preventDefault();

        let array = Array.from(props.showOeChildren);
        let index = array.indexOf(sorId);
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(sorId);
            sessionStorage.setItem('scrollPosition', window.pageYOffset);
        }
        props.setShowOeChildren(array);

        props.handleClick(_id);
    }

    /*This function boost SorId so if an entity is clicked it appears on top as first child of a given parent.*/
    const treeClickBuilder = () => {
        if (props.showSorId !== null) {
            return ({ "match": { "SorId.keyword": { "query": props.showSorId, "boost": 2 } } })
        }
        return ({ "match_all": {} });
    }


    /*This function determines if the given entity´s children should be rendered.
    It has 3 if statements that corresponds to the 3 different types of parents(OeEntity, SIEntity, Lvl4Bucket)*/
    const handleParentTypeChange = () => {

        let handleParentType = false;

        if (props.showSiChildren)
            handleParentType = props.showSiChildren.indexOf(props.parentKey) !== -1 ? true : false;

        if (!handleParentType && props.showLvl4Children)
            handleParentType = props.showLvl4Children.indexOf(props.parentKey) !== -1 ? true : false;

        if (!handleParentType && props.showOeChildren)
            handleParentType = props.showOeChildren.indexOf(props.parentKey) !== -1 ? true : false;

        return handleParentType;
    }

    /*This function builds the Elastic search query based on the users selection in the build self tree.*/
    const objectBuilder = (bucket, key) => {
        switch (bucket) {
            case "InstitutionOwnerEntityTypeName":
                return ({ "match": { "InstitutionOwnerEntityTypeName.keyword": key } })

            case "SorType":
                return ({ "match": { "SorType.keyword": key } })

            case "PostalPostCodeId":
                return ({ "match": { "PostalPostCodeId": key } })

            case "PostalMunicipalityName":
                return ({ "match": { "PostalMunicipalityName.keyword": key } })

            case "PostalAddressRegionName":
                return ({ "match": { "PostalAddressRegionName.keyword": key } })

            case "SpecialityName":
                return ({ "match": { "SpecialityName.keyword": key } })

            case "GeographicalLocalisationName":
                return ({ "match": { "GeographicalLocalisationName.keyword": key } })

            default: return ({ "match_all": {} })

        }


    }

    const renderData = (data, resultStats) => {

        if (data.length > 0) {

            let showCircle = parseInt(props.showCircle);
            let level = parseInt(props.level);


            let circles = []
            let nextLevel = level + 1
            let nextShowCircle = showCircle + 1
            for (let i = 0; i < showCircle; i++) {
                circles.push(<FontAwesomeIcon key={i} icon="circle" />)
            }
            return (
                <React.Fragment>
                    {data.map((result) =>
                        <li className={"search-result--tree__circle search-result--tree__circle-" + showCircle} key={result._id}>
                            <span className="search-result--tree__inner-wrapper" data-selected={props.selectedOrNot(result._id)}>
                                <a id={result.SorId} className={props.showOeChildren.indexOf(result.SorId) === -1 ? "collapsed" : ""} data-selected={props.selectedOrNot(result._id)} href={"#Oe" + result._id} onClick={(e) => handleOeEntityChildClick(e, result._id, result.SorId)}>
                                    <span className="circle">{circles}</span>
                                    <span className="caret-right"><FontAwesomeIcon icon="caret-right" /></span>
                                    <span className="caret-down"><FontAwesomeIcon icon="caret-down" /></span>
                                    <span className="search-result--tree__entityname" dangerouslySetInnerHTML={handleHtmlMarkup(result.EntityName)} />
                                </a>
                                <TreeFields showFields={props.showFields} setShowTreeLines={props.setShowTreeLines}
                                    EntityTypeName={result.EntityTypeName}
                                    OrganizationalLevelName={result.OrganizationalLevelName}
                                    SorType={result.SorType}
                                    PostalAddress={result.PostalAddress}
                                    PostalPostCodeId={result.PostalPostCodeId}
                                    PostalDistrictName={result.PostalDistrictName}
                                    VisitingAddress={result.VisitingAddress}
                                    VisitingAddressPostCodeId={result.VisitingAddressPostCodeId}
                                    VisitingAddressDistrictName={result.VisitingAddressDistrictName}
                                    SpecialityName={result.SpecialityName}
                                    InstitutionOwnerCvrNumberId={result.InstitutionOwnerCvrNumberId}
                                    SorId={result.SorId}
                                    ShakId={result.ShakId}
                                    EanLocationCode={result.EanLocationCode}
                                    ProviderId={result.ProviderId}
                                    PharmacyId={result.PharmacyId}
                                />
                            </span>

                            <ul id={"Oe" + result._id} className={props.showOeChildren.indexOf(result.SorId) === -1 ? "collapse" : "show"}>
                                <OeEntity parentKey={result.SorId} handleClick={props.handleClick} showCircle={nextShowCircle} level={nextLevel} showFields={props.showFields}
                                    showSiChildren={props.showSiChildren} setShowSiChildren={props.setShowSiChildren}
                                    showOeChildren={props.showOeChildren} setShowOeChildren={props.setShowOeChildren} 
                                    selectedOrNot={props.selectedOrNot} setShowTreeLines={props.setShowTreeLines} showSorId={props.showSorId} />
                            </ul>

                        </li>
                    )
                    }

                    {resultStats.displayResults < resultStats.numberOfResults &&
                        <React.Fragment>
                            <span className="result-stats">Viser {resultStats.displayResults} ud af {resultStats.numberOfResults} </span>
                            <button type="button" className="btn btn-sm btn-link" onClick={props.loadMoreData}>Hent flere enheder</button>
                        </React.Fragment>
                    }
                </React.Fragment>

            )
        }
        return null;
    }


    if (handleParentTypeChange()) {
        return (
            <ReactiveList
                componentId={"OeReactiveList" + props.parentKey}
                dataField="EntityName"
                className="search-results--tree"
                loader="Henter enheder"
                renderNoResults={() => { return null; }}
                showResultStats={false}
                react={{
                    and: ["TodataSearch"]
                }}
                pagination={false}
                size={100}
                defaultQuery={() => {
                    if (props.showLvl4Children) {
                        return ({
                            "sort": [{ "EntityName.keyword": { "order": "asc" } }],
                            "query": {
                                "bool": {
                                    "must": [

                                        objectBuilder(props.level1Bucket, props.resultKey),
                                        objectBuilder(props.level2Bucket, props.result2Key),
                                        objectBuilder(props.level2Bucket, props.result2Key),
                                        objectBuilder(props.level3Bucket, props.result3Key),
                                        objectBuilder(props.level4Bucket, props.result4Key)

                                    ],

                                }
                            }
                        })
                    }
                    return ({
                        "sort": { "EntityName.keyword": { "order": "asc" } },
                        "query": {
                            "bool": {
                                "must": [
                                    {
                                        "match": { "ParentSorId": props.parentKey }
                                    },
                                    {
                                        "match": { "SorType": "OE" }
                                    }

                                ],
                                "should": treeClickBuilder()
                            }
                        }

                    })
                }
                }
                render={({
                    loading,
                    error,
                    data,
                    resultStats
                }) => renderData(data, resultStats)}
            />
        )
    }
    return null;

}
export default OeEntity