import React from 'react';
import { ReactiveList } from '@appbaseio/reactivesearch'
import SiEntity from './SiEntity'
import TreeFields from './TreeFields'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretDown, faCircle } from '@fortawesome/free-solid-svg-icons'

library.add(faCaretRight, faCaretDown, faCircle)

/*This component renders entities with a Level = 1. It subsequently calls SiEntity for children of a given entity.*/
function IeEntity(props) {

    /*This function calls two functions in StaticTree so a given entity´s details is displayed and the children displayed. */
    const handleIeEntityClick = (e, _id, sorId) => {
        e.preventDefault()
        let array = Array.from(props.showIeChildren);
        let index = array.indexOf(sorId);
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(sorId);
            sessionStorage.setItem('scrollPosition', window.pageYOffset);
        }
        props.setShowIeChildren(array);
        props.handleClick(_id)
    }

    /*This function boost SorId so if an entity is clicked it appears on top as first child of a given parent.*/
    const treeClickBuilder = () => {
        if (props.showSorId !== null) {
            return ({ "match": { "SorId.keyword": { "query": props.showSorId, "boost": 2 } } })
        }
        return ({ "match_all": {} });
    }

    const renderData = (data, resultStats) => {

        let level = parseInt(props.level);
        let showCircle = parseInt(props.showCircle);

        let circles = []
        let nextLevel = level + 1
        let nextShowCircle = showCircle + 1
        for (let i = 0; i < showCircle; i++) {
            circles.push(<FontAwesomeIcon key={i} icon="circle" />)
        }

        return (
            <React.Fragment>
                {data.map((result) =>
                    <li className={"search-result--tree__circle search-result--tree__circle-" + showCircle} key={result._id}>
                        <span className="search-result--tree__inner-wrapper" data-selected={props.selectedOrNot(result._id)}>
                            <a id={result.SorId} className={props.showIeChildren.indexOf(result.SorId) === -1 ? "collapsed" : ""}
                                href={"#Si" + result._id} onClick={(e) => handleIeEntityClick(e, result._id, result.SorId)}>
                                <span className="circle">
                                    {circles}
                                </span>
                                <span className="caret-right"><FontAwesomeIcon icon="caret-right" /></span>
                                <span className="caret-down"><FontAwesomeIcon icon="caret-down" /></span>
                                <span className="search-result--tree__entityname">{result.EntityName}</span>
                            </a>
                            <TreeFields showFields={props.showFields} setShowTreeLines={props.setShowTreeLines}
                                EntityTypeName={result.EntityTypeName}
                                SorType={result.SorType}
                                PostalAddress={result.PostalAddress}
                                PostalPostCodeId={result.PostalPostCodeId}
                                PostalDistrictName={result.PostalDistrictName}
                                VisitingAddress={result.VisitingAddress}
                                VisitingAddressPostCodeId={result.VisitingAddressPostCodeId}
                                VisitingAddressDistrictName={result.VisitingAddressDistrictName}
                                SpecialityName={result.SpecialityName}
                                InstitutionOwnerCvrNumberId={result.InstitutionOwnerCvrNumberId}
                                SorId={result.SorId}
                                ShakId={result.ShakId}
                                EanLocationCode={result.EanLocationCode}
                                ProviderId={result.ProviderId}
                                PharmacyId={result.PharmacyId}

                            />
                        </span>
                        <ul className={props.showIeChildren.indexOf(result.SorId) === -1 ? "collapse" : "show"} id={"Si" + result._id} key={"Si" + result._id}>
                            <SiEntity selectedOrNot={props.selectedOrNot} parentSorId={result.SorId} handleClick={props.handleClick}
                                showCircle={nextShowCircle} level={nextLevel} showFields={props.showFields}
                                setShowIeChildren={props.setShowIeChildren} showIeChildren={props.showIeChildren}
                                setShowSiChildren={props.setShowSiChildren} showSiChildren={props.showSiChildren}
                                setShowOeChildren={props.setShowOeChildren} showOeChildren={props.showOeChildren}
                                setShowTreeLines={props.setShowTreeLines} showSorId={props.showSorId} />
                        </ul>
                    </li>
                )}

                {resultStats.displayResults < resultStats.numberOfResults &&
                    <React.Fragment>
                        <span className="result-stats">Viser {resultStats.displayResults} ud af {resultStats.numberOfResults} </span>
                        <button type="button" className="btn btn-sm btn-link" onClick={props.loadMoreData}>Hent flere enheder</button>
                    </React.Fragment>
                }

            </React.Fragment>
        )
    }


    return (
        <ReactiveList
            componentId={"IeReactiveList" + props.parentKey + (props.firstletter ? props.firstletter : "")}
            dataField="EntityName"
            className="search-results--tree"
            loader="Henter enheder"
            renderNoResults={() => { return null; }}
            showResultStats={false}
            childTotal={null}
            handleChildTotal={function (total) { if (total != null) { this.childTotal = total } }}
            pagination={false}
            size={100}
            react={{
                and: ["TodataSearch", "InstitutionOwnerEntityNameSearch"] //"EntityTypeNameSearch",
            }}
            defaultQuery={() => {
                if (props.firstletter) {
                    return ({
                        "track_scores": true,
                        "sort": ["_score", { "EntityName.keyword": { "order": "asc" } }],
                        "query": {
                            "bool": {
                                "must": [
                                    {
                                        "match": {
                                            "EntityTypeName.keyword": props.parentKey
                                        }

                                    },

                                    {
                                        "match": {
                                            "Level.keyword": "1"
                                        }
                                    },
                                    {
                                        "prefix": { "EntityName.keyword": { "value": props.firstletter } }
                                    }

                                ],
                                "should": treeClickBuilder()
                            },
                        },
                    })
                } else return ({
                    "track_scores": true,
                    "sort": ["_score", { "EntityName.keyword": { "order": "asc" } }],
                    "query": {
                        "bool": {
                            "must": [
                                {
                                    "match": {
                                        "EntityTypeName.keyword": props.parentKey
                                    }
                                },

                                {
                                    "match": {
                                        "Level.keyword": "1"
                                    }
                                }
                            ],
                            "should": treeClickBuilder()
                        },
                    }
                })
            }
            }
            render={({
                loading,
                error,
                data,
                resultStats
            }) => renderData(data, resultStats)}
        />
    )
}

export default IeEntity
