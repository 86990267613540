
import React, { useState, useEffect } from 'react'

import RegionBucket from './RegionBucket'
import FirstLetterBucket from './FirstLetterBucket'
import IeEntity from './IeEntity'

function StaticTree(props) {

    const [showRegionChildren, setShowRegionChildren] = useState([]);
    const [showFirstLetterChildren, setShowFirstLetterChildren] = useState([]);
    const [showIeChildren, setShowIeChildren] = useState([]);
    const [showSiChildren, setShowSiChildren] = useState([]);
    const [showOeChildren, setShowOeChildren] = useState([]);
    const [showTreeLines, setShowTreeLines] = useState(false);
    const [scrollTo, setScrollTo] = useState(null);

    useEffect(() => {
        if (scrollTo) {
            let element = document.getElementById(scrollTo);
            if (element) {
                setScrollTo(null);
                element.scrollIntoView(true);
                const yOffset = window.pageYOffset - 200;
                window.setTimeout(function () {
                    window.scrollTo(0, yOffset);
                }, 200);
                window.setTimeout(function () {
                    window.scrollTo(0, yOffset);
                }, 400);
                window.setTimeout(function () {
                    window.scrollTo(0, yOffset);
                }, 800);
            }
        }
        if (props.showTreeList && props.showEntityTypeName) {
            let treeList = Array.from(props.showTreeList);
            let to = "" + treeList[0];
            props.setShowTreeList(null);
            const length = props.showTreeList.length;

            handleRegionClick(props.showEntityTypeName);
            if (props.showEntityTypeName === "Privat") {
                handleFirstLetterClick(props.showFirstLetter);
            }
            handleIeEntityClick(treeList[0]);

            if (length > 1) {
                handleSiEntityClick(treeList[1]);
                to = "" + treeList[1];
            }

            if (length > 2) {
                let oeArray = treeList.splice(2);
                let array = Array.from(showOeChildren);
                array.push(...oeArray);
                setShowOeChildren(array);
                to = "" + array[array.length - 1];
            }
            setScrollTo(to);
        }
    });


    /*This function calls two functions in StaticTree so a given entity´s details is displayed and the children displayed.*/
    const handleSiEntityClick = (sorId) => {
        let array = Array.from(showSiChildren);
        let index = array.indexOf(sorId);
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(sorId);
        }
        setShowSiChildren(array);
    }


    /*Handle clicks on “Privat” aggregations which is mapped to first letter
    When clicked the state is updated and the entities which starts with the given letter is displayed.*/
    const handleFirstLetterClick = (key) => {
        let array = Array.from(showFirstLetterChildren);
        let index = array.indexOf(key);
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(key);
        }
        setShowFirstLetterChildren(array);
    }


    /*Handle clicks on the first aggregation (“Privat”, “Kommune”, “Stat”, “Region”). 
    When clicked the state is updated and the sub aggregation of the clicked entity is displayed.*/
    const handleRegionClick = (key) => {
        let array = Array.from(showRegionChildren);
        let index = array.indexOf(key);
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(key);
        }
        setShowRegionChildren(array);
    }

    /* Handle clicks on entities on the first level. 
    When clicked the state is updated and the entities which are children of the given entity is displayed.*/
    const handleIeEntityClick = (_id) => {
        let array = Array.from(showIeChildren);
        let index = array.indexOf(_id);
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(_id);
        }
        setShowIeChildren(array);
    }


    const getClassName = (key) => {
        return showRegionChildren.indexOf(key) !== -1 ? "show" : "collapse";
    }

    const FirstLetters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "X", "Y", "Z", "Æ", "Ø", "Å", "1", "2", "3", "4", "5", "6", "7", "8", "9"]

    if (props.aggregations) {


        return (
            <div id="search-results--tree" className={showTreeLines ? "search-results--tree search-results--tree-lines" : "search-results--tree"}>
                <ul>
                    {props.aggregations.group_by_region.buckets.map((region) =>
                        <li key={region.key} className="search-result--tree__circle-1">
                            <RegionBucket region={region} setShowRegionChildren={setShowRegionChildren} showRegionChildren={showRegionChildren} />

                            <ul className={getClassName(region.key)} id={"Re" + region.key}>
                                {showRegionChildren.indexOf(region.key) !== -1 &&
                                    <React.Fragment>
                                        {region.key === "Privat" &&
                                            FirstLetters.map((firstletter) =>
                                                <li key={firstletter} className="search-result--tree__circle-2">
                                                    <FirstLetterBucket firstLetter={firstletter} showFirstLetterChildren={showFirstLetterChildren} setShowFirstLetterChildren={setShowFirstLetterChildren} />

                                                    <ul className={showFirstLetterChildren.indexOf(firstletter) === -1 ? "collapse" : "show"}
                                                        id={"FirstLetter" + firstletter}>
                                                        {showFirstLetterChildren.indexOf(firstletter) !== -1 &&
                                                            <IeEntity selectedOrNot={props.selectedOrNot} showCircle="3" level="1" parentKey={region.key} handleClick={props.handleClick}
                                                                setShowIeChildren={setShowIeChildren} showIeChildren={showIeChildren}
                                                                setShowSiChildren={setShowSiChildren} showSiChildren={showSiChildren}
                                                                setShowOeChildren={setShowOeChildren} showOeChildren={showOeChildren} 
                                                                showFields={props.showFields} firstletter={firstletter} setShowTreeLines={setShowTreeLines} showSorId={props.showSorId} />
                                                        }
                                                    </ul>
                                                </li>
                                            )
                                        }
                                        {region.key !== "Privat" &&
                                            <IeEntity selectedOrNot={props.selectedOrNot} showCircle="2" level="1" parentKey={region.key} handleClick={props.handleClick}
                                                setShowIeChildren={setShowIeChildren} showIeChildren={showIeChildren}
                                                setShowSiChildren={setShowSiChildren} showSiChildren={showSiChildren}
                                                setShowOeChildren={setShowOeChildren} showOeChildren={showOeChildren}
                                                showFields={props.showFields} setShowTreeLines={setShowTreeLines} showSorId={props.showSorId} />
                                        }
                                    </React.Fragment>
                                }

                            </ul>
                        </li>
                    )}
                </ul>
            </div >
        )
    }
    return null;

}

export default StaticTree

