import { useState } from 'react'

/* The Fields component render a dropdown menu with a list of fields the user can select/deselect.
The state and click event is handled in Searchpage.jsx and thereby consistent across search result components.  */

function Fields(props) {

    const [visibility, setVisibility] = useState("hidden");
    const handleShowFieldsClick = props.handleShowFieldsClick;

    const showDropdown = () => {
        setVisibility("show");

        // create covering element
        let cover = document.createElement("div")
        cover.classList.add("sor-screen-cover")
        cover.addEventListener("click", hideDropdown)

        // apply covering element
        document.querySelector("body").prepend(cover)
    }

    const hideDropdown = () => {
        setVisibility("hidden");
        // remove the covering element
        document.querySelector(".sor-screen-cover").remove()
    }

        function handleChange(event) {
            // Here, we invoke the callback with the new value
            props.handleShowFieldsClick(event);
        }

    const toggleDropdown = (e) => {

        // with a few exceptions, this method will toggle the dropdown and cover
        let blacklist = [
            "form-check-input",
            "form-check-label"
        ]

        const doToggle = blacklist.reduce((accumulator, item) => {
            if (e.target.classList.contains(item)) {
                accumulator = false
            }
            return accumulator
        }, true)

        if (doToggle) {
            switch (visibility) {
                case "show":
                    hideDropdown()
                    break;
                case "hidden":
                    showDropdown()
                    break;
                default: break;
            }
        }
    }

    return (
        <div className="sor-dropdown attribute-dropdown" onClick={toggleDropdown}>
            <button className="btn btn-sm btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="sor-dropdown" aria-haspopup="true" aria-expanded="false">
                Vis Felter
            </button>
            <div className={"dropdown-menu keep-open p-5 " + visibility} aria-labelledby="dropdownMenuButton">
                <div className="form-check mb-2">
                    <label className="form-check-label" htmlFor="CheckEntityTypeName">
                        <input className="form-check-input" type="checkbox" value="EntityTypeName" id="CheckEntityTypeName" onChange={handleShowFieldsClick} defaultChecked={props.showFields.indexOf("CheckEntityTypeName") !== -1} />

                        Enhedstype
                    </label>
                </div>
                {/*ID-27*/}
                <div className="form-check mb-2">  
                    <label className="form-check-label" htmlFor="CheckOrganizationalLevelName">
                        <input className="form-check-input" type="checkbox" value="OrganizationalLevelName" id="CheckOrganizationalLevelName" onChange={handleShowFieldsClick} defaultChecked={props.showFields.indexOf("CheckOrganizationalLevelName") !== -1} />
                        Klinisk adm. niveau
                    </label>
                </div>
                <div className="form-check mb-2">
                    <input className="form-check-input" type="checkbox" value="Adress" id="CheckAddress" onChange={handleShowFieldsClick} defaultChecked={props.showFields.indexOf("CheckAddress") !== -1} />
                    <label className="form-check-label" htmlFor="CheckAddress">
                        Adresse
                    </label>
                </div>
                <div className="form-check mb-2">
                    <input className="form-check-input" type="checkbox" value="SpecialityName" id="CheckSpecialityName" onChange={handleShowFieldsClick} defaultChecked={props.showFields.indexOf("CheckSpecialityName") !== -1} />
                    <label className="form-check-label" htmlFor="CheckSpecialityName">
                        Speciale
                    </label>
                </div>
                <div className="form-check mb-2">
                    <input className="form-check-input" type="checkbox" id="CheckInstitutionOwnerCvrNumberId" onChange={handleShowFieldsClick} defaultChecked={props.showFields.indexOf("CheckInstitutionOwnerCvrNumberId") !== -1} />
                    <label className="form-check-label" htmlFor="CheckInstitutionOwnerCvrNumberId">
                        CVR nummer
                    </label>
                </div>
                <div className="form-check mb-2">
                    <input className="form-check-input" type="checkbox" value="SorId" id="CheckSorId" onChange={handleShowFieldsClick} defaultChecked={props.showFields.indexOf("CheckSorId")  !== -1} />
                    <label className="form-check-label" htmlFor="CheckSorId">
                        SOR kode
                    </label>
                </div>
                <div className="form-check mb-2">
                    <input className="form-check-input" type="checkbox" value="ShakId" id="CheckShakId" onChange={handleShowFieldsClick} defaultChecked={props.showFields.indexOf("CheckShakId") !== -1} />
                    <label className="form-check-label" htmlFor="CheckShakId">
                        SHAK kode
                    </label>
                </div>
                <div className="form-check mb-2">
                    <input className="form-check-input" type="checkbox" value="EanLocationCode" id="CheckEanLocationCode" onChange={handleShowFieldsClick} defaultChecked={props.showFields.indexOf("CheckEanLocationCode") !== -1} />
                    <label className="form-check-label" htmlFor="CheckEanLocationCode">
                        Lokationsnummer
                    </label>
                </div>
                <div className="form-check mb-2">
                    <input className="form-check-input" type="checkbox" value="ProviderId" id="CheckProviderId" onChange={handleShowFieldsClick} defaultChecked={props.showFields.indexOf("CheckProviderId") !== -1} />
                    <label className="form-check-label" htmlFor="CheckProviderId">
                        Ydernummer
                    </label>
                </div>
                <div className="form-check mb-2">
                    <input className="form-check-input" type="checkbox" value="PharmacyId" id="CheckPharmacyId" onChange={handleShowFieldsClick} defaultChecked={props.showFields.indexOf("CheckPharmacyId") !== -1} />
                    <label className="form-check-label" htmlFor="CheckPharmacyId">
                        Apoteksnummer
                    </label>
                </div>
            </div>
        </div>

    )
}
export default Fields