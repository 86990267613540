import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Support from './Support/Support';
import './App.css';
import SearchPage from './SearchPage';
import './index.scss';
import CacheBuster from 'react-cache-buster';
import packageJson from '../package.json';   // Overkill but react will not allow import of less...

class App extends Component {
    render() {
        var version = packageJson.version;    

        console.log("SOR Browser " + version + " (ID-27)");

        //console.log("REACT_APP_INDEX = " + process.env.REACT_APP_INDEX);
        //console.log("REACT_APP_URL = " + process.env.REACT_APP_URL);
        //console.log("REACT_APP_USER = " + process.env.REACT_APP_USER);
        //console.log("REACT_APP_MAPKEY = " + process.env.REACT_APP_MAPKEY);
        //console.log("REACT_APP_CSV_API_URL = " + process.env.REACT_APP_CSV_API_URL);
        //console.log("REACT_APP_HELP_API_URL = " + process.env.REACT_APP_HELP_API_URL);


        const isDev = process.env.NODE_ENV === 'development';

        // *** NOTICE ***
        // Version must be set in package.json and nowhere else.
        // To make it available for CacheBuster a Post-build event will call the 'generate-meta-tag' script 
        // (defined in package.json) which will duplicate the value to public/meta.json

        return (
            <CacheBuster currentVersion={version} isEnabled={true} isVerboseMode={isDev}>
                <BrowserRouter>
                    <Routes>
                        <Route exact path="/"            element={<SearchPage/>}/>
                        <Route exact path="/map"         element={<SearchPage />} />
                        <Route exact path="/tree"        element={<SearchPage />} />
                        <Route exact path="/support"     element={<Support />} />
                    </Routes>
                </BrowserRouter>
            </CacheBuster>
        )

        /* 
        * Denne side vises n�r SOR ikke er tilg�ngelig
        *
         return (
               <div>
                   <header className="row search-header">
                       <div className="logo-container"><img className="logo" src="logo.png" alt="logo"></img></div>
                       <h1>SOR - Sundhedsv�senets Organisationsregister</h1>
                   </header>
                   <br/><br/>
                   <div style={{ textAlign : 'center' }}>
                       <h3>SOR Browser er ikke tilg�ngelig i �jeblikket.</h3>
                       <br/><br/>
                       <h3>Der arbejdes p� at l�se problemet.</h3>
                   </div>
               </div>
           )
           */

    }
}
export default App;


