import React, { useState } from 'react';
import { ReactiveList } from '@appbaseio/reactivesearch';
import { ReactiveGoogleMap } from '@appbaseio/reactivemaps';
import reactArray from '../components/reactArray';
import handleHtmlMarkup from '../components/HandleHtmlMarkup';
import ExportCsv from '../components/ExportCsv';


/* This component renders the search results in a map view. It uses Googles map api and Reactive map
https://opensource.appbase.io/reactive-manual/map-components/reactivemap.html */

function MapSearch(props) {

    const [noPost, setNoPost] = useState(-1);

    /*This function sets the state with the number of entities that have no PostalAddressGeoLocation and there cannot be displayed on the map. 
    The result comes from the SearchResultNoPostadress component.*/
    const handleNoMapResults = (total) => {
        if (total > 0 && total !== noPost)
            setNoPost(total);
        else
            setNoPost(-1);
    }

    return (

        <React.Fragment>

            <div className="col-12  mb-3">
                <div className="row justify-content-end">
                    <ExportCsv />
                </div>
            </div>

            <ReactiveList
                componentId="SearchResultNoPostadress"
                dataField="PostalAddressGeoLocation"
                loader="Henter enheder"
                defaultQuery={() => ({
                    "query": {
                        "bool": {
                            "must_not":
                            {
                                "exists": {
                                    "field": "PostalAddressGeoLocation"
                                }
                            }
                        }
                    }
                })
                }
                render={({ loading, error, data }) => { return null; }}
                showResultStats={false}
                renderNoResults={() => { return null; }}
                onData={(result) => {
                    handleNoMapResults(result.resultStats.numberOfResults)
                }}
                react={{ and: reactArray("SearchResultNoPostadress") }}
            />

            <ReactiveList
                componentId="SearchResult"
                dataField="PostalAddressGeoLocation"
                showResultStats={true}
                renderNoResults={(stats) => {
                    return (
                        <React.Fragment>Ingen resultater fundet.
                            {noPost !== null && <div>Der er {noPost} enhed/enheder som ikke har nogen Postadresse koordinater.
                                <br />Gå til Søgningsiden for at se alle enheder der matcher din søgning </div>}
                        </React.Fragment>
                    )
                }}
                loader={<span></span>}
                defaultQuery={() => ({
                    "query": {
                        "bool": {
                            "must":
                            {
                                "exists": {
                                    "field": "PostalAddressGeoLocation"
                                }
                            }
                        }
                    }
                })
                }
                renderResultStats={(stats) => {
                    if (stats.numberOfResults > 300) {
                        return ("Viser 300 ud af " + stats.numberOfResults + " resultater " + stats.time + "ms. " + (noPost === -1 ? "" : noPost + " enheder har ikke gyldige koordinater"))
                    }
                    else return ("Viser " + stats.numberOfResults + " resultater " + stats.time + "ms. " + (noPost === -1 ? "" : noPost + " enheder har ikke gyldige koordinater"))
                }}
                render={({ loading, error, data }) => { return null; }}

                react={{ and: reactArray("SearchResult") }}
            />

            <ReactiveGoogleMap
                componentId="MapUI"
                dataField="PostalAddressGeoLocation"
                title="Kort"
                size={300}
                defaultZoom={7}
                defaultRadius={1000}
                defaultCenter={{ lat: "54.6625845003074", lng: "11.3517201210305" }}
                showMapStyles={true}
                defaultMapStyle="Standard"
                showMarkerClusters={true}
                showSearchAsMove={true}
                searchAsMove={false}
                unit="km"
                onPopoverClick={(res) => {
                    props.handleClick(res._id)
                    return (
                        <div>
                            <h6 dangerouslySetInnerHTML={handleHtmlMarkup(res.EntityName)} />
                            <div><label>Enhedstype:</label> {res.EntityTypeName}</div>
                            <div><span dangerouslySetInnerHTML={handleHtmlMarkup(res.PostalAddress)} /> {res.PostalPostCodeId} {res.PostalDistrictName}</div>
                            {res.Treelist &&
                                <button type="button" data-toggle="tooltip" data-placement="right" title="Visning i organisatorisk træ" className="btn btn-link"
                                    onClick={() => props.handleTreeClick(res._id, res.InstitutionOwnerEntityTypeName, res.InstitutionOwnerEntityName, res.Treelist)}>Vis i organisatorisk træ</button>
                            }
                        </div>
                    )
                }}
                react={{
                    and: reactArray("MapUI"),
                    not: "SearchResultNoPostadress"
                }}
                autoCenter={false}
            />


        </React.Fragment>
    )
}
export default MapSearch
